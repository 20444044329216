import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import { useStaticQuery, graphql } from 'gatsby'

import { Link } from 'gatsby'

// import pic01 from '../assets/images/pic01.jpg'
// import pic02 from '../assets/images/pic02.jpg'
// import pic03 from '../assets/images/pic03.jpg'
// import pic04 from '../assets/images/pic04.jpg'
// import pic05 from '../assets/images/pic05.jpg'
// import pic06 from '../assets/images/pic06.jpg'


export default ({ data }) => {
    const lessons = data.allContentfulLayoutHighlightedCourse.edges[0].node.lessons;
    console.log(lessons)
    return (
            <Layout>
                <Helmet
                    title="STUDYROOM"
                    meta={[
                        { name: 'description', content: 'STUDYROOM IST EIN NACHHILFE & SPRACHINSTITUT AUSGERICHTET AUF IHRE BEDÜRFNISSE STUDYROOM ERÖFFNET IHNEN NEUE MÖGLICHKEITEN DURCH EINE GEFESTIGTERE FREMDSPRACHE WIR UNTERSTÜTZEN DABEI SCHULISCHE WIE AUCH BERUFLICHEN UND AKADEMISCHEN ZIELE ZU ERREICHEN' },
                        { name: 'keywords', content: 'Englisch Nachhilfe,Nachhilfe, Sprachinstitut, business englisch, Sprachschule, fremdsprachenschule, Fremdsprachentraining, englisch berufstätige, englisch Senioren. Englisch Unternehmen' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                    {lessons.map((lesson) => (
                        <article style={{backgroundImage: `url(${lesson.photo.file.url})`}}>
                            <header className="major">
                                <h3>{lesson.title}</h3>
                                <p>{lesson.shortDescription.shortDescription}</p>
                            </header>
                            <Link to={"/lesson/" + lesson.slug} className="link primary"></Link>
                        </article>
                    ))}
                        {/* <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>SCHULEN</h3>
                                <p>Nachhilfeunterricht für Schüler vorort</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>ENGLISCH BEWERBUNGSTRAINING</h3>
                                <p>Studyroom bietet die richtige Vorbereitung für Ihr Vorstellungsgespräch und ihrer Bewerbung</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Sommerferientraining</h3>
                                <p>Effektive Wiederholung & perfekte Vorbereitung auf das neue Schuljahr</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>BERUFSTÄTIGE</h3>
                                <p>Berufsspezifisches Einzel- und Gruppentraining in Englisch und weiteren Sprachen</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>SENIOREN</h3>
                                <p>Spezielles Sprachtraining für Senioren in Englisch, Französisch und Spanisch</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Unternehmen, Firmen & Start-ups</h3>
                                <p>Studyroom bietet ein effektives Englisch Training für Ihre Mitarbeiter</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article> */}
                    </section>
                    {/* <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>WHAT PEOPLES SAYS</h2>
                            </header>
                            <p>Samir: Studyroom help my son a lot to improve his confidence to learn German effictlvy with an innovative approch!</p>
                            <p>Asmae: Studyroom help my son a lot to improve his confidence to learn English effictlvy with an innovative approch!</p>

                            <ul className="actions">
                                <li><Link to="/landing" className="button next">CONTACT</Link></li>
                            </ul>
                        </div>
                    </section> */}
                </div>

            </Layout>
    )
}

export const query = graphql`
  query allContentfulLayoutHighlightedCourse {
    allContentfulLayoutHighlightedCourse {
        edges {
            node{
                id
                title
                lessons {
                    id
                    title
                    slug
                    shortDescription {
                        shortDescription
                    }
                    photo {
                        id
                        file {
                            url
                            fileName
                            contentType
                        }
                    }
                }
            }
        }
    }
  }
`