import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }


const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-left">{children}</p>

export default ({props}) => {

    const data = useStaticQuery(
        graphql`
          query {
            allContentfulLayoutHero {
                nodes {
                    id
                    title
                    extraDescription {
                      id
                      extraDescription
                      json
                    }
                    image {
                        id
                        file {
                        url
                        fileName
                        contentType
                        }
                    }
                }
            }
          }
    `)

    const htmlContent = data.allContentfulLayoutHero.nodes[0].extraDescription.json;
    return (
        <section id="banner" className="major">
            <div className="inner">
                <header className="major">
                    <h1>{data.allContentfulLayoutHero.nodes[0].title}</h1>
                </header>
                {/* <div className="content">
                    {data.allContentfulLayoutHero.nodes[0].description.description}
                </div> */}
                <div id="extraBanner">
                    <div className="extraB">
                        {documentToReactComponents(htmlContent, options)}
                    </div>
                    <ul className="actions">
                        <li><a href="#contact" className="button next scrolly">ANMELDUNG</a></li>
                    </ul>
                    
                </div>
            </div>
        </section>
    )
}